import { render, staticRenderFns } from "./Overview.vue?vue&type=template&id=70e52741&scoped=true&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70e52741",
  null
  
)

export default component.exports